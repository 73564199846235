/* eslint-disable react/jsx-wrap-multilines */
import { AppBar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AlertSection from 'components-v2/atoms/AlertSection';
import Header from 'components-v2/layout/Header';
import { defaultOptionsTab } from 'components-v2/layout/Header/mobile/HeaderIconButton';
import NavbarMobile from 'components-v2/layout/NavbarMobileThai';
import Navbar from 'components-v2/layout/NavbarThai';
import FooterThai from 'components-v2/organisms/FooterThai';
import PopupBinding from 'components-v3/mocules/PopupBinding';
import PopupBindingMobile from 'components-v3/mocules/PopupBindingMobile';
import { ButtonHeader } from 'components/atoms/Button';
import HeadPage from 'components/atoms/HeadPage';
import { BRAND_NAME } from 'constants/Enums';
import { CART_URL, PRODUCT_LIST, QUICK_ORDER } from 'constants/Paths';
import { useAuth, useCart } from 'context';
import { usePopup } from 'context/Popup';
import { useModal } from 'hooks';
import useFirebase from 'hooks/useFirebase';
import useHandleTrial from 'hooks/useHandleTrial';
import useTimeout from 'hooks/useTimeout';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';
import { CustomerService } from 'services';
import { ENV_COUNTRY, HOTLINE, NOTIFY_IN_TOP_BAR, RECAPTCHA_SITE_KEY } from 'sysconfig';
import { CookiesParser, routeHandler } from 'utils';
import { getSessionTokenClient } from 'utils/SessionUtils';
import { useStore } from 'zustand-lib/storeGlobal';
import useMobileV2 from 'zustand-lib/storeMobile';
import FooterWithCart from '../FooterMobile/components/FooterWithCart';
import styles from './styles.module.css';

const DELAY_REQUEST_MODAL = 5000;

const Footer = dynamic(() => import('components-v2/organisms/Footer'), { ssr: false });
const CustomModal = dynamic(() => import('components/mocules/CustomModal'), { ssr: false });
const NewRegisterGuestModal = dynamic(() => import('components/organisms/NewRegisterGuestModal'), { ssr: false });
const NewSignInModal = dynamic(() => import('components/organisms/NewSignInModal'), { ssr: false });
const NewSignUpModal = dynamic(() => import('components/organisms/NewSignUpModal'), { ssr: false });
const NewChangePasswordModal = dynamic(() => import('components/organisms/NewChangePasswordModal'), { ssr: false });
const NewForgetPasswordModal = dynamic(() => import('components/organisms/NewForgetPasswordModal'), { ssr: false });
const FooterMobile = dynamic(() => import('../FooterMobile'), { ssr: false });
const HeaderMobile = dynamic(() => import('../HeaderMobile'), { ssr: false });
const HeaderMobileNew = dynamic(() => import('components-v2/layout/Header/mobile'), { ssr: false });
const FooterMobileNew = dynamic(() => import('components-v2/layout/Footer/mobile'), { ssr: false });

// modal
const RequestModal = dynamic(() => import('components/organisms/RequestModal'), { ssr: false });
const PopupFullScreen = dynamic(() => import('components-v2/PopupFullScreen'), { ssr: false });

const defaultMV2Options = {
  offHeader: false,
  title: '',
  CustomRightHeader: false, // => truyền vào components -> nếu có CustomRightHeader thì iconRightHeader sẽ ko hiển thị
  offIconRight: false,
  iconRightHeader: {
    // các icon right header muốn hiện (có sắp xếp theo thứ tự)
    notify: true,
    cart: true,
    userinfo: true,
    toQuickOrder: false,
    home: false,
  },
};

const mapFooter = ({ locale, isMobile }) => {
  switch (locale) {
    case 'th': {
      return <FooterThai isMobile={isMobile} />;
    }
    default: {
      return <Footer />;
    }
  }
};

// this template is using for PC. mobile v1 and mobile
export default function Template({
  title = '',
  children,
  isMobile,
  pageName,
  pageTitle = '',
  product = '',
  point = 0,
  balance = 0,
  showTopSearchMV2 = false,
  overrideMV2Options = {},
  isWebView = false,
  hideNavbar = false,
  showMobileFooter = false,
}) {
  const {
    isAuthenticated,
    toggleLogin,
    isShowLogin,
    handleChangeForget,
    isShowForgetPassword,
    toggleForgetPassword,
    toggleChangePassword,
    isShowChangePassword,
    isShowSignUp,
    toggleSignUp,
    handleChangeSignIn,
    handleChangeSignUp,
    registerGuest,
    isShowRegisterGuest,
    toggleRegisterGuest,
    toggleShowGuestExpiredTime,
    isShowGuestExpiredTime,
    isShowForgotPassMess,
    toggleShowForgotPassMess,
    isShowBlockAccountMess,
    toggleShowBlockAccountMess,
    isShowNotFoundAccountMess,
    toggleShowNotFoundAccountMess,
    getValShowResponseNotFoundAccountMess,
    isShowDeleteAccountMess,
    toggleShowDeleteAccountMess,
    customerInfo,
    user,
    auth,
  } = useAuth();
  const router = useRouter();
  const appConfig = useStore((state) => state.appConfig);
  const { setCanShowNextPopup } = usePopup();

  const { t: translateCommon } = useTranslation('common');
  // const [showInputTaxtInfoModal, setShowInputTaxInfoModal] = useState(false);
  const [openRequestModal, toggleRequestModal] = useModal();
  const toggleBeta = useMobileV2((state) => state.toggleBeta);
  const isMobileV2 = useMobileV2((state) => state.isMobileV2());
  const beta = useMobileV2((state) => state.beta);

  const { clearCart, isShowModalMax200Item, toggleShowModalMax200Item, ...rest } = useCart();
  const { pathname, asPath, ...restRouter } = useRouter();

  const [checkSupportNotification] = useFirebase(user);

  useHandleTrial(user, isMobile, toggleBeta, isMobileV2);

  const openModal = () => {
    const IS_SHOW_REQUEST_MODAL = 'isShowRequestModal';
    const isShowRequestModal = CookiesParser.CookiesParser(document.cookie)[IS_SHOW_REQUEST_MODAL];
    if (!isAuthenticated && !isShowRequestModal && !isShowLogin && !isShowSignUp) {
      toggleRequestModal();
      document.cookie = `${IS_SHOW_REQUEST_MODAL}=true;`;
    }
  };

  useTimeout(openModal, DELAY_REQUEST_MODAL);

  const { status = '' } = customerInfo || {};

  let mv2Options = {};
  if (beta || pathname === '/conversations') {
    const isBottomNavigation = routeHandler.isBottomNavigation({ pathname, asPath, ...restRouter });
    if (!overrideMV2Options.iconRightHeader && !isBottomNavigation) {
      mv2Options = {
        ...mv2Options,
        ...defaultOptionsTab,
      };
    } else {
      mv2Options = {
        ...defaultMV2Options,
        ...overrideMV2Options,
      };
    }
    mv2Options = {
      ...mv2Options,
      title: overrideMV2Options?.title ? overrideMV2Options?.title : pageTitle,
    };
  }

  useEffect(() => {
    if (!isAuthenticated && isShowGuestExpiredTime) {
      clearCart();
    }
  }, [isShowGuestExpiredTime]);

  useEffect(() => {
    if (user) {
      checkSupportNotification();
    }
  }, [user]);

  // not show popup require invoice thuannc 08May2024
  // requester : Toàn

  // #remove_tax_require_popup
  // const loadDataInvoice = async () => {
  //   const invoiceInfoList = await CustomerClient.getListInvoiceInfo({});
  //   const shouldShowInputTaxInfoModal = !getData(invoiceInfoList)?.length;
  //   setShowInputTaxInfoModal(shouldShowInputTaxInfoModal);
  // };

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const invoiceRes = loadDataInvoice();
  //     if (invoiceRes.status === 'OK') {
  //       const isShowInputTaxInfoModal = invoiceRes.data && Array.isArray(invoiceRes.data) && !invoiceRes.data.length;
  //       setShowInputTaxInfoModal(isShowInputTaxInfoModal);
  //     }
  //   }
  // }, []);

  // show popup binding if user is not binding with LINE

  const [showBindingLINE, setShowBindingLINE] = useState(false);
  const bindingLINEURLRef = useRef('');

  const checkShowBindingLINEPopUpByAppConfig = () => {
    try {
      const appBindingLINEConfig = appConfig.find((config) => config?.value?.key === 'APP_CONFIG_BINDING_LINE');
      const appBindingLINEConfigValue = appBindingLINEConfig?.value?.value || -1; // -1 mean not show popup
      return appBindingLINEConfigValue > 0;
    } catch {
      return false;
    }
  };

  const saveTimeConfirmBindingLINE = () => {
    const currentTime = new Date();
    localStorage.setItem('timeBindingLINE', currentTime);
  };

  const checkTimeToShowBindingLINEPopUp = () => {
    const currentTime = new Date();
    const timeBindingLINE = localStorage.getItem('timeBindingLINE');
    if (!timeBindingLINE) {
      return true;
    }
    const timeDiff = currentTime.getTime() - new Date(timeBindingLINE).getTime();
    const timeDiffInMinutes = timeDiff / 60000;
    if (timeDiffInMinutes >= 60 * 24) {
      // 60 * 24 = 1 day
      return true;
    }
    return false;
  };

  const loadBindingLINEInfo = async ({ customerID = '' }) => {
    try {
      const bindingReps = await CustomerService.getBindingLINE({ customerID: customerID.toString() });
      if (bindingReps && !bindingReps?.line_uid?.length) {
        // not binding LINE
        const url = bindingReps?.binding_link || '';
        bindingLINEURLRef.current = url;
        localStorage.setItem('isBindingLINE', false);
        // show popup binding LINE
        setShowBindingLINE(true);
      } else if (bindingReps) {
        setShowBindingLINE(false);
        setCanShowNextPopup(true);
        localStorage.setItem('isBindingLINE', true);
      }
    } catch (e) {
      toast.error(translateCommon('load_binding_LINE_error_message'));
    } finally {
      saveTimeConfirmBindingLINE();
    }
  };

  const handleCloseBindingLINEPopup = () => {
    setCanShowNextPopup(true);
    setShowBindingLINE(false);
  };

  const handleRedirectToBindingLINE = () => {
    if (bindingLINEURLRef.current.length) {
      window.open(bindingLINEURLRef.current, '_blank').focus();
      setShowBindingLINE(false);
    } else {
      toast.error(translateCommon('not_found_binding_LINE_link'));
    }
  };

  useEffect(() => {
    const token = getSessionTokenClient();
    if (token) {
      const isBindingLINE = localStorage.getItem('isBindingLINE');
      if (isBindingLINE === 'true') {
        setCanShowNextPopup(true);
        return;
      }

      const shouldShowBindingLINEPopUpByAppConfig = checkShowBindingLINEPopUpByAppConfig();
      if (!shouldShowBindingLINEPopUpByAppConfig) {
        setCanShowNextPopup(true);
        return;
      }

      const shouldShowBindingLINEPopUp = checkTimeToShowBindingLINEPopUp();
      if (shouldShowBindingLINEPopUp) {
        loadBindingLINEInfo({ customerID: user.customerID });
      } else {
        setCanShowNextPopup(true);
      }
    }
  }, []);

  let notifyWidth = 0;
  if (NOTIFY_IN_TOP_BAR) {
    notifyWidth = isMobile ? 129 : 0;
  }
  const checkRenderUIHeader = () => {
    const { offHeader, title: headerTitle, iconRightHeader, CustomRightHeader, offIconRight } = mv2Options;
    if (offHeader || isWebView) {
      return <></>;
    }
    if (beta || pathname === '/conversations') {
      // header mmv2
      return (
        <HeaderMobileNew
          title={headerTitle}
          isFooterPath={!!user}
          renderRightIcons={iconRightHeader}
          CustomRightHeader={CustomRightHeader}
          offIconRight={offIconRight}
          isAuthenticated={isAuthenticated}
          user={user}
        />
      );
    }

    // header mv1
    return <HeaderMobile title={pageTitle} isMobile={isMobile} />;
  };

  const checkRenderUIFooter = () => {
    if (beta) {
      // show footer cart for quick order and cart page
      if (pathname === QUICK_ORDER || pathname === CART_URL) {
        return (
          <AppBar position="fixed" className={styles.appBar}>
            <FooterWithCart />
          </AppBar>
        );
      }

      if (routeHandler.isBottomNavigation({ pathname, asPath, ...restRouter })) {
        return (
          <div style={{ marginTop: '75px' }}>
            <FooterMobileNew
              translateCommon={translateCommon}
              isAuthenticated={isAuthenticated}
              toggleSignUp={toggleSignUp}
              toggleLogin={toggleLogin}
            />
          </div>
        );
      }
    }
    if (showMobileFooter) {
      return (
        <div style={{ marginTop: '75px' }}>
          <FooterMobileNew
            translateCommon={translateCommon}
            isAuthenticated={isAuthenticated}
            toggleSignUp={toggleSignUp}
            toggleLogin={toggleLogin}
          />
        </div>
      );
    }
    return <></>;
  };

  const MobileProdPage = [PRODUCT_LIST, '/manufacturers/[slug]', '/tag/[slug]'];
  const marginTopForHeader = () => {
    if (isMobile && beta && MobileProdPage.includes(pathname)) return '48px';
    if (isMobile && !mv2Options.offHeader) return '55px';

    return '0px';
  };

  // const handleCloseTaxInfoModal = () => setShowInputTaxInfoModal(false);

  const handleCloseLogin = () => {
    toggleLogin();
    if (isShowDeleteAccountMess) {
      toggleShowDeleteAccountMess();
    }
  };

  return (
    <div className={styles.container}>
      <HeadPage title={title} />
      <div id="main" className={clsx(beta && styles.new_bg_mobile, isMobile && styles.bg_mobile)}>
        {/* {isMobile ? checkRenderUIHeader() : <Header isMobile={isMobile} />} */}
        {!router.query.isHideHeader && (
          <>
            <Header isMobile={isMobile} />
            {!isMobile && !hideNavbar && <Navbar pageName={pageName} point={point} balance={balance} />}
            {isMobile && !hideNavbar && !showMobileFooter && <NavbarMobile pageName={pageName} point={point} balance={balance} isMobile={isMobile} />}
          </>
        )}
        {/* Placeholder for NOTIFY_IN_TOP_BAR */}
        {pageName !== 'conversations' && (
          <AlertSection
            beta={beta}
            pathname={pathname}
            isMobile={isMobile}
            notifyWidth={notifyWidth}
            marginTopForHeader={marginTopForHeader}
            isAuthenticated={isAuthenticated}
            status={status}
            asPath={asPath}
            isMobileProdPage={beta && isMobile && MobileProdPage.includes(pathname)}
            isMobileV2={isMobileV2}
          />
        )}

        {/* {isMobile && pageName !== 'conversations' && (
          <TopSearchMobile translateCommon={translateCommon} isNewMobile={beta} showTopSearchMV2={showTopSearchMV2} />
        )} */}
        {children}
        {isMobile && isAuthenticated ? (
          <></>
        ) : (
          mapFooter({
            locale: ENV_COUNTRY,
            isMobile,
          })
        )}
        {isMobile && <>{checkRenderUIFooter()}</>}
        {isMobile && beta && !isAuthenticated ? (
          <PopupFullScreen user={user} />
        ) : (
          !isAuthenticated && (
            <>
              <NewSignInModal
                visible={isShowLogin}
                onClose={handleCloseLogin}
                onChangeForget={handleChangeForget}
                onChangeSignUp={handleChangeSignUp}
              />
              <NewForgetPasswordModal visible={isShowForgetPassword} onClose={toggleForgetPassword} />
              <GoogleReCaptchaProvider language="en" reCaptchaKey={RECAPTCHA_SITE_KEY}>
                <NewSignUpModal visible={isShowSignUp} onClose={toggleSignUp} onChangeSignIn={handleChangeSignIn} className="signup-modal" />
              </GoogleReCaptchaProvider>

              <NewRegisterGuestModal visible={isShowRegisterGuest} onClose={toggleRegisterGuest} onChangeRegisterGuest={registerGuest} />
              <CustomModal
                visible={isShowGuestExpiredTime}
                onClose={toggleShowGuestExpiredTime}
                content={translateCommon('message_time_out')}
                btnOkRender={false}
                btnCloseRender={false}
                customBtnRender={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className={styles.btngroup}>
                    <ButtonHeader variant="contained" btnType="primary" onClick={toggleSignUp}>
                      {translateCommon('create_an_account')}
                    </ButtonHeader>
                  </div>
                }
              />
              {isShowForgotPassMess && (
                <CustomModal
                  visible={isShowForgotPassMess}
                  onClose={toggleShowForgotPassMess}
                  content={translateCommon('message_wrong_pass').replace('{BRAND_NAME}', BRAND_NAME)}
                  btnOkRender={false}
                  btnCloseRender={false}
                  customBtnRender={
                    <div className={styles.btngroup}>
                      <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowForgotPassMess}>
                        {translateCommon('i_understand')}
                      </ButtonHeader>
                    </div>
                  }
                />
              )}
            </>
          )
        )}
        {isShowBlockAccountMess && (
          <CustomModal
            visible={isShowBlockAccountMess}
            onClose={toggleShowBlockAccountMess}
            content={
              <Typography className={styles.text_modal}>
                {translateCommon('message_block_account_1')} <a href={`tel:${HOTLINE}`}>{HOTLINE}</a> {translateCommon('message_block_account_2')}
              </Typography>
            }
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowBlockAccountMess}>
                  {translateCommon('i_understand')}
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isShowNotFoundAccountMess && (
          <CustomModal
            visible={isShowNotFoundAccountMess}
            onClose={toggleShowNotFoundAccountMess}
            content={`${translateCommon('message_not_found_account').replace('{BRAND_NAME}', BRAND_NAME)} ${JSON.stringify(
              getValShowResponseNotFoundAccountMess(),
            )}`}
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowBlockAccountMess}>
                  {translateCommon('i_understand')}
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isShowModalMax200Item && (
          <CustomModal
            visible={isShowModalMax200Item}
            onClose={toggleShowModalMax200Item}
            content={translateCommon('message_over_limit_200_item_in_cart')}
            btnOkRender={false}
            btnCloseRender={false}
            customBtnRender={
              <div className={styles.btngroup}>
                <ButtonHeader variant="contained" btnType="primary" onClick={toggleShowModalMax200Item}>
                  {translateCommon('i_understand')}
                </ButtonHeader>
              </div>
            }
          />
        )}
        {isAuthenticated && isShowChangePassword && <NewChangePasswordModal visible={isShowChangePassword} onClose={toggleChangePassword} />}
        {/* {isAuthenticated && showInputTaxtInfoModal && <InputTaxInfoModel open={showInputTaxtInfoModal} handleClose={handleCloseTaxInfoModal} />} */}
        {isAuthenticated &&
          showBindingLINE &&
          (!isMobile ? (
            <PopupBinding
              title={translateCommon('binding_account')}
              content={translateCommon('binding_account_with_LINE_message')}
              image="/images/binding-line.png"
              closeText={translateCommon('cancel')}
              executeText={translateCommon('link_your_account')}
              isOpen={showBindingLINE}
              onClose={handleCloseBindingLINEPopup}
              onExcute={handleRedirectToBindingLINE}
            />
          ) : (
            <PopupBindingMobile
              title={translateCommon('binding_account')}
              content={translateCommon('binding_account_with_LINE_message')}
              image="/images/binding-line-mobile.png"
              closeText={translateCommon('cancel')}
              executeText={translateCommon('link_your_account')}
              isOpen={showBindingLINE}
              onClose={handleCloseBindingLINEPopup}
              onExcute={handleRedirectToBindingLINE}
            />
          ))}
      </div>

      {/* mặc định nếu chưa đăng nhập thì mobile dùng fb  */}
      {/* TODO: FbMessenger  FEATURE-CHAT */}
      {/* {!isAuthenticated && isMobile && isShowDelay && FACEBOOK_MESSENGER_ID && <Facebook />} off su dung Chatthuocsi thay chat fb */}
      {/* nếu không phải mobile => web => check setting  */}
      {/* {!isMobile && false && <>{MapChatComponent[chatSetting]}</>} */}
      {openRequestModal && <RequestModal visible={openRequestModal} onClose={toggleRequestModal} />}
    </div>
  );
}
