import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { ENV_COUNTRY, GENERAL_DOMAIN } from 'sysconfig';
import { checkNewUser, checkTrialSelected } from 'utils/transVersion';

const COOKIE_KEY = 'trial_v2';

const useHandleTrial = (user, isMobile = false, toggleBeta, isMobileV2) => {
  useEffect(() => {
    (() => {
      if (!user) return;

      if (isMobileV2) return;

      if (checkNewUser(user?.account?.createdTime) || ENV_COUNTRY !== 'vn') {
        toggleBeta();
        return;
      }
      // chỉ force v2 lần đầu còn các lần sau render theo setting của user
      const isTrialSelected = checkTrialSelected(user);
      const isForceV2 = Cookies.get(COOKIE_KEY);
      if (isTrialSelected && !isForceV2 && isMobile) {
        toggleBeta();
        Cookies.set(COOKIE_KEY, true, {
          domain: GENERAL_DOMAIN,
          expires: 180,
          sameSite: 'Lax',
        });
      }
    })();
  }, [user?.accountID]);
};

export default useHandleTrial;
