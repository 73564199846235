/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable arrow-body-style */
import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@mui/icons-material';
import MostSearch from 'components-v2/atoms/MostSearch';
import { LinkComp } from 'components/atoms';
import { LOGO_BUYMED_GG } from 'constants/Images/default';
import { INGREDIENT, PRODUCTS_LOADING_URL, SELLERS, getPathProductBySlug } from 'constants/Paths';
import { HOAT_CHAT, NHA_BAN_HANG, THUOC, THUOC_VA_HOAT_CHAT } from 'constants/data';
import { useSearch } from 'context';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useEffect, useMemo, useState } from 'react';
import { MarketingService } from 'services';
import { gtag } from 'utils';
import { formatCurrency } from 'utils/FormatNumber';
import ImageFallback, { ImageFallbackStoreImage } from 'utils/ImageFallback';
import { groupArrayOfObjects } from 'utils/ObjectUtils';
import { v4 as uuidv4 } from 'uuid';
import styles from './styles.module.css';

const getTrendSearch = async ({ limit = 6 }) => {
  let trendSearchList = [];
  try {
    const trendSearchResp = await MarketingService.getTrendSearchs({ isActive: true, limit });
    if (trendSearchResp.status === 'OK') {
      trendSearchList = trendSearchResp?.data || [];
    } else {
      trendSearchList = [];
    }
  } catch (e) {
    trendSearchList = [];
  }

  return trendSearchList;
}

// TODO: logic search
const SearchDropdown = (props) => {
  const router = useRouter();
  const { t: translateCommon } = useTranslation('common');
  const { keyword, type, hashtagTopSearch, isMobile, handleClickOutside, searchDropDownRef } = props;
  const [searchRedirect, setSearchRedirect] = useState('');
  const [searchHistory, setSearchHistory] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const { searchProduct, searchIngredient, searchSeller, isFocus } = useSearch();
  const [trendSearch, setTrendSearch] = useState([]);

  const handleAddSearchHistory = ({ name, slug }) => {
    const existingSearchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
    const hasNameSearched = existingSearchHistory.find((search) => search?.name === name);

    if (!hasNameSearched) {
      const newSearchHistory = [{ name, slug }, ...searchHistory];
      setSearchHistory(newSearchHistory);
      localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));
    }
  };

  const handleClearSearchHistory = ({ name }) => {
    const newSearchHistory = searchHistory?.filter((search) => search?.name !== name);
    setSearchHistory(newSearchHistory);
    localStorage.setItem('searchHistory', JSON.stringify(newSearchHistory));
  };

  const handleClearAllSearchHistory = () => {
    setSearchHistory([]);
    localStorage.clear('searchHistory');
  };

  const handleClickSearchHistory = (search) => {
    let link = '';
    switch (type) {
      case THUOC_VA_HOAT_CHAT:
      case THUOC: {
        link = getPathProductBySlug(search?.slug);
        break;
      }
      case HOAT_CHAT: {
        link = `${INGREDIENT}/${search?.slug}`;
        break;
      }
      default: {
        link = '';
      }
    }
    router.push(link);
    handleClickOutside();
  };

  const renderSearchResultsByType = useMemo(() => {
    switch (type) {
      case THUOC_VA_HOAT_CHAT:
      case THUOC: {
        const listSearch = searchResults.map((item) => {
          const { name = '', displayPrice = 0, retailPriceValue = 0, sellerInfo = {}, slug = '', volume = '', imageUrls = [] } = item;
          const displayPriceFormated = formatCurrency(displayPrice) || '';
          const salePriceFormated = formatCurrency(retailPriceValue) || '';
          return {
            ...item,
            imageUrls,
            name,
            slug,
            volume,
            displayPrice,
            displayPriceFormated,
            salePriceFormated,
            sellerName: sellerInfo?.sellerName || '',
          };
        });
        const groupedProductByName = groupArrayOfObjects(listSearch, 'name');
        return Object.values(groupedProductByName).flatMap((listProduct) =>
          listProduct
            ?.sort((a, b) => a?.displayPrice - b?.displayPrice)
            ?.map((item) => {
              return (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                  className={styles.searchResults}
                  key={uuidv4()}
                  onClick={() => {
                    handleAddSearchHistory({ name: item?.name || '', slug: item?.slug || '' });
                    router.push(getPathProductBySlug(item?.slug));
                    handleClickOutside();
                  }}
                >
                  <div className={styles.searchResultsImage}>
                    <ImageFallbackStoreImage
                      src={item?.imageUrls[0]}
                      fallbalSrc={LOGO_BUYMED_GG}
                      width={!isMobile ? '52px' : '48px'}
                      height={!isMobile ? '52px' : '48px'}
                    />
                  </div>
                  <div className={styles.searchResultsContent}>
                    <span className={styles.name}>{item?.name}</span>
                    {item?.status === 'OUT_OF_STOCK' ? (
                      <span className={styles.outOfStockMessage}>{translateCommon('temporarily_out_of_stock')}</span>
                    ) : (
                      <div>
                        <span className={styles.displayPrice}>{item?.displayPriceFormated || ''}</span>
                        {item?.displayPriceFormated !== item?.salePriceFormated && (
                          <span className={styles.salePrice}>{item?.salePriceFormated || ''}</span>
                        )}
                        {item?.volume?.length !== 0 && <span className={styles.volume}>&#124; {item?.volume?.toLowerCase() || ''}</span>}
                      </div>
                    )}
                  </div>
                </div>
              );
            }),
        );
      }
      case HOAT_CHAT: {
        const searchRes = searchResults.map((item) => ({ name: item.name, link: `${INGREDIENT}/${item?.slug}` }));
        return searchRes.map((item) => (
          <div
            className={styles.searchResults}
            key={uuidv4()}
            onClick={() => {
              handleAddSearchHistory({ name: item?.name });
              router.push(item.link);
              handleClickOutside();
            }}
          >
            <span className={styles.name}>{item?.name}</span>
          </div>
        ));
      }
      case NHA_BAN_HANG: {
        return searchResults.map((item) => (
          <LinkComp className={styles.searchResults} key={uuidv4()} href={item.link}>
            <span className={styles.name}>{item?.name}</span>
          </LinkComp>
        ));
      }
      default: {
        return <></>;
      }
    }
  }, [keyword, searchResults, type, translateCommon]);

  const renderTopSearch = useMemo(() => {
    if (!hashtagTopSearch.length) {
      return <></>
    }

    return (
      <div className={styles.hotTagWrapper}>
        <p className={styles.hotTagTitle}> {translateCommon('hot_tag')} </p>
        <Grid container alignItems="center">
          {hashtagTopSearch?.map((tag) => (
            <MostSearch onClick={() => gtag.clickTopSearch(tag)} key={tag.code} link={tag.url} className={styles.mostSearch}>
              {tag.hashtag}
            </MostSearch>
          ))}
        </Grid>
      </div>
    );
  }, [hashtagTopSearch, translateCommon]);

  const renderSearchHistory = useMemo(() => {
    if (!searchHistory?.length) {
      return <></>;
    }

    return (
      <>
        <Grid container justifyContent="space-between" className={styles.searchHeader}>
          <Grid item xs={5}>
            <Typography display="inline" className={styles.titleSearchHistory}>
              {translateCommon('search_history')}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <span className={styles.clearAllSearch} onClick={handleClearAllSearchHistory}>
              {translateCommon('clear_all_history')}
            </span>
          </Grid>
        </Grid>
        <div className={styles.searchResultsWapper}>
          {searchHistory.map((search) => (
            <div key={uuidv4()} className={styles.searchResults}>
              <div className={styles.searchHistoryIcon}>
                <IconButton style={{ padding: 0 }}>
                  <ImageFallbackStoreImage src="/images/icon/clock.svg" fallbalSrc={LOGO_BUYMED_GG} width={20} height={20} />
                </IconButton>
              </div>
              <div className={styles.searchHistoryTitle} onClick={() => handleClickSearchHistory(search)}>
                <span className={styles.name}>{search?.name}</span>
              </div>
              <div className={styles.searchHistoryAction}>
                <IconButton style={{ padding: 0 }}>
                  <Close width={20} height={20} style={{ color: '#919191' }} onClick={() => handleClearSearchHistory(search)} />
                </IconButton>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  }, [searchHistory, translateCommon]);

  const renderTrendSearch = useMemo(() => {
    if (!trendSearch?.length) {
      return <></>;
    }

    return (
      <div className={styles.trendSearchWrapper}>
        <p className={styles.trendSearchTitle}>{translateCommon('trending')}</p>
        <Grid container spacing={1}>
          {trendSearch.map((item, _) => (
            <Grid item xs={12} md={6} key={`${item?.code || ''}`}>
              <Grid container spacing={1} alignItems='center'>
                <Grid item>
                  <ImageFallback
                    src={item?.image}
                    fallbalSrc={LOGO_BUYMED_GG}
                    alt={item?.title}
                    width={50}
                    height={50}
                  />
                </Grid>
                <Grid item>
                  <Link href={item?.url || ''}>
                    <a href={item?.url || ''}>
                      <Tooltip title={item?.title || ''} placement='top'>
                        <Typography component='span' className={styles.trendSearchText}>{item?.title || ''}</Typography>
                      </Tooltip>
                    </a>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }, [trendSearch, translateCommon]);

  useEffect(() => {
    const searchTypeConfig = {
      [THUOC_VA_HOAT_CHAT]: {
        newSearchRedirect: `${PRODUCTS_LOADING_URL}?text=${keyword}`,
        newSearchResults: searchProduct,
      },
      [THUOC]: {
        newSearchRedirect: `${PRODUCTS_LOADING_URL}?text=${keyword}`,
        newSearchResults: searchProduct,
      },
      [HOAT_CHAT]: {
        newSearchRedirect: INGREDIENT,
        newSearchResults: searchIngredient,
      },
      [NHA_BAN_HANG]: {
        newSearchRedirect: `${SELLERS}?search=${keyword}`,
        newSearchResults: searchSeller,
      },
    };
    if (type && searchTypeConfig[type]) {
      setSearchResults(searchTypeConfig[type].newSearchResults);

      if (searchTypeConfig[type].newSearchRedirect !== searchRedirect) {
        setSearchRedirect(searchTypeConfig[type].newSearchRedirect);
      }
    }
  }, [keyword, type, searchProduct, searchIngredient, searchSeller]);

  useEffect(async () => {
    if (isFocus) {
      const currentSearchHistory = localStorage.getItem('searchHistory') ? JSON.parse(localStorage.getItem('searchHistory')) : [];
      if (currentSearchHistory?.length > 0) {
        setSearchHistory([...currentSearchHistory]);
      }

      if (trendSearch.length === 0) {
        const limit = isMobile ? 3 : 6;
        const trendSearchList = await getTrendSearch({ limit });
        setTrendSearch(trendSearchList);
      }
    }
  }, [isFocus]);

  if (!isFocus) {
    return <></>;
  }

  return (
    <Box className={styles.searchDropdown} data-test="search-dropdown" id="searchDropDown" ref={searchDropDownRef}>
      {
        (!searchResults || searchResults?.length === 0) && keyword?.length === 0 && isFocus // First click - Show search history
          ? renderSearchHistory
          : (
            <>
              <LinkComp className={styles.searchHeader} href={searchRedirect}>
                {translateCommon('header.search_in')}:&nbsp;
                <span className={styles.textPrimary}>{translateCommon(`search_type.${type}`)}</span>
              </LinkComp>
              <div className={styles.searchResultsWapper}>{renderSearchResultsByType}</div>
            </>
          )
      }
      {renderTopSearch}
      {renderTrendSearch}
    </Box>
  );
};

export default memo(
  SearchDropdown,
  (prevProps, nextProps) =>
    prevProps?.keyword === nextProps?.keyword && prevProps?.type === nextProps?.type && prevProps?.isMobile === nextProps?.isMobile,
);
