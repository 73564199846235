import { Box, Button, Modal, Typography } from "@material-ui/core";
import Image from "next/image";

const modalBoxStyle = {
  position: "absolute",
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  background: "#FFF",
  borderRadius: 12,
  border: 'none',
  boxShadow: 24,
  p: 3,

};

const imageContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '182px',
  height: '48px',
  margin: '24px auto 0'
};

const titleContainerStyle = {
  mt: 3,
}

const titleStyle = {
  fontFamily: 'ggsm',
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '28px',
  color: '#000'
}

const contentContainerStyle = {
  mt: 3,
}

const contentStyle = {
  color: "#000",
  textAlign: 'justify',
  fontFamily: 'ggsr',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '22px',
}

const actionContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  mt: 3,
}

const executeButtonStyle = {
  display: 'flex',
  height: 44,
  minWidth: 120,
  padding: '0px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  marginBottom: 12,
  fontFamily: 'ggsm',
  fontSize: 16,
  fontWeight: 500
}

const closeButtonStyle = {
  border: 'none',
  fontFamily: 'ggsm',
  fontSize: 16,
  fontWeight: 500
}

const PopupBinding = ({
  title = '',
  content = '',
  image = '',
  closeText = 'Close',
  executeText = 'Execute',
  isOpen = false,
  onClose = () => { },
  onExcute = () => { }
}) => (
  <Modal open={isOpen} onClose={() => { }}>
    <Box sx={modalBoxStyle}>
      {
        !!image.length && (
          <Box sx={imageContainerStyle}>
            <Image src={image} alt={title} layout="fill" width={40} />
          </Box>
        )
      }
      <Box sx={titleContainerStyle}>
        <Typography variant="h6" component="h2" style={titleStyle}>
          {title}
        </Typography>
      </Box>
      <Box sx={contentContainerStyle}>
        <Typography variant="body1" component="p" style={contentStyle}>
          {content}
        </Typography>
      </Box>
      <Box sx={actionContainerStyle}>
        <Button onClick={onExcute} variant="contained" color="primary" style={executeButtonStyle}>
          {executeText}
        </Button>
        <Button onClick={onClose} variant="outlined" style={closeButtonStyle}>
          {closeText}
        </Button>
      </Box>
    </Box>
  </Modal>
)

export default PopupBinding;