import { Box, Button, Dialog, Slide, Typography } from '@material-ui/core';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const dialogStyle = {
  position: 'absolute',
  marginBottom: 0,
  bottom: 0
}

const modalBoxStyle = {
  position: "fixed",
  bottom: '0%',
  left: '0%',
  width: '100%',
  bgcolor: 'background.paper',
  background: "#FFF",
  padding: '12px 16px 24px 16px',
  border: 'none',
  borderRadius: '16px 16px 0px 0px',
};

const imageContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '329px',
  height: '143px',
  margin: '12px auto 0'
};

const titleContainerStyle = {
  mt: 3,
}

const titleStyle = {
  fontFamily: 'ggsm',
  textAlign: 'center',
  fontSize: 20,
  fontWeight: 600,
  lineHeight: '28px',
  color: '#000'
}

const contentContainerStyle = {
  mt: 3,
}

const contentStyle = {
  color: "#000",
  textAlign: 'justify',
  fontFamily: 'ggsr',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '22px',
}

const actionContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  mt: 3,
}

const executeButtonStyle = {
  display: 'flex',
  height: 44,
  minWidth: 120,
  padding: '0px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  marginBottom: 12,
  fontFamily: 'ggsm',
  fontSize: 16,
  fontWeight: 500
}

const executeLinkStyle = {
  backgroundColor: '#00AB55',
  color: '#FFF',
  display: 'flex',
  height: 44,
  minWidth: 120,
  padding: '0px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  marginBottom: 12,
  fontFamily: 'ggsm',
  fontSize: 16,
  fontWeight: 500
}

const closeButtonStyle = {
  border: 'none',
  fontFamily: 'ggsm',
  fontSize: 16,
  fontWeight: 500
}

const PopupBindingMobile = ({
  title = '',
  content = '',
  image = '',
  closeText = 'Close',
  executeText = 'Execute',
  isOpen = false,
  onClose = () => { },
  onExcute = () => { },
  link = ''
}) => (
  <Dialog
    open={isOpen}
    maxWidth="sm"
    fullWidth
    TransitionComponent={Transition}
    style={dialogStyle}
  >
    <Box sx={modalBoxStyle}>
      {
        !!image.length && (
          <Box sx={imageContainerStyle}>
            <Image src={image} alt={title} layout="fill" width={40} />
          </Box>
        )
      }
      <Box sx={titleContainerStyle}>
        <Typography variant="h6" component="h2" style={titleStyle}>
          {title}
        </Typography>
      </Box>
      <Box sx={contentContainerStyle}>
        <Typography variant="body1" component="p" style={contentStyle}>
          {content}
        </Typography>
      </Box>
      <Box sx={actionContainerStyle}>
        {
          !link.length ? (
            <Button onClick={onExcute} variant="contained" color="primary" style={executeButtonStyle}>
              {executeText}
            </Button>
          ) : (
            <Link href={link}>
              <a href={link} target='_blank' rel="noreferrer" style={executeLinkStyle}>
                {executeText}
              </a>
            </Link>
          )
        }
        <Button onClick={onClose} variant="outlined" style={closeButtonStyle}>
          {closeText}
        </Button>
      </Box>
    </Box>
  </Dialog>
)

export default PopupBindingMobile;