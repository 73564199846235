export const IC_HEADER_NOTIFICATION = '/static/images/header/notification_ic.svg';
export const IC_HEADER_CART = '/static/images/header/cart_ic.svg';
export const IC_HEADER_USER = '/static/images/header/user_ic.svg';
export const IC_HEADER_EDITOR = '/static/images/header/editor_ic.svg';
export const IC_NAVBAR_USER = '/static/images/header/user_green_ic.svg';
export const IC_NAVBAR_EDITOR = '/static/images/header/editor_green_ic.svg';

export default {
  IC_HEADER_NOTIFICATION,
  IC_HEADER_CART,
  IC_HEADER_USER,
  IC_HEADER_EDITOR,
  IC_NAVBAR_USER,
  IC_NAVBAR_EDITOR,
};
