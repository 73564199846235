import { Grid, Modal, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { PromoClient, getData, isValid } from 'clients';
import clsx from 'clsx';
import InputV2 from 'components-v2/atoms/InputV2';
import LoadingBM from 'components-v2/atoms/LoadingBM';
import CartCouponCardV2 from 'components-v2/mocules/CartCouponCardV2';
import { useCart } from 'context';
import { useDetectScrollModal } from 'hooks';
import { useTranslation } from 'next-i18next';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { debounceFunc500 } from 'utils/debounce';
import styles from './styles.module.css';

const TEXT_DEFAULT = '';
const OFFSET_DEFAULT = 0;
// TODO:PROMOTION
const PromoListModalV2 = memo((props) => {
  const { t: translatePopupAction } = useTranslation('popup-action');

  const {
    onClose,
    visible,
    restProps,
    redeemCode,
    handleChangePromo,
    subPrice,
    accountId,
    customerID,
    currentPage,
    isMobile,
    redeemApplyResult,
    paymentMethod,
    price,
    totalItem,
    cartItems,
  } = props;

  const { tempRedeemApplyResult, regionCodes } = useCart();

  // const isMobileDevice
  const mountRef = useRef(false);
  const isInitialMount = useRef(true);
  const [promoList, setPromoList] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [text, setText] = useState('');
  const [offset, setOffset] = useState(OFFSET_DEFAULT);
  const [loading, setLoading] = useState(false);

  // const [isHasMore, setIsHasMore] = useState(true);
  // const [totalList, setTotalList] = useState([]);

  async function getVouchers(applyResult = tempRedeemApplyResult || [], search = '') {
    setLoading(true);
    try {
      const voucherResp = await PromoClient.postVoucherList({
        accountID: accountId,
        cart: {
          paymentMethod,
          price,
          totalItem,
          redeemCode,
          cartItems: cartItems
            ?.filter((item) => item.isSelected)
            ?.map((item) => ({
              quantity: item.quantity,
              price: item.price,
              total: item.total,
              sku: item.sku,
              sellerCode: item.sellerCode,
              productTags: item.productTags,
              productCode: item.productCode,
              isSelected: item.isSelected,
            })),
          // redeemApplyResult,
          redeemApplyResult: applyResult,
          regionCodes,
        },
        scope: 'marketplace',
        search,
        limit: 100,
      });
      if (isValid(voucherResp)) {
        setPromoList(getData(voucherResp));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const handleUpdateText = useCallback(
    async (value) => {
      getVouchers(tempRedeemApplyResult, value);
      // const result = await PromoClient.getVoucherActiveConfig({ offset: 0, search: value });
      // setPromoList(getData(result));
      // if (text === '' || promoList.length >= 20) {
      //   setIsHasMore(true);
      // }
    },
    [tempRedeemApplyResult],
  );

  const handleChangeText = (valueText) => {
    // const valueText = e.target.value;
    // // mặc định api trả ra data length = 20 nếu nhỏ hơn thì ko load more
    // if (promoList?.length < 20) {
    //   setIsHasMore(false);
    // }
    setText(valueText);
    debounceFunc500(() => {
      handleUpdateText(valueText);
    });
  };
  const handleRemoveText = () => {
    setText(TEXT_DEFAULT);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    async function getConfigVoucherList() {
      if (text !== '') {
        setOffset(0);
      }
      if (!mountRef.current) {
        setLoading(true);
        mountRef.current = true;
      }
      const resultConfig = await PromoClient.getVoucherActiveConfig({ offset, search: text, signal });
      // const { total = 0 } = resultConfig;
      // setTotalList(total);
      if (isValid(resultConfig)) {
        setPromoList(getData(resultConfig));
      }
      setLoading(false);
    }

    // getVouchers();
    // getConfigVoucherList();
    return () => controller.abort();
  }, [text === '', offset]);

  useEffect(() => {
    if (visible) {
      getVouchers();
    } else {
      setPromoList([]);
    }
  }, [visible]);

  const searchPromo = useCallback(
    async (search) => {
      console.log(search);

      // if (isSending) return;
      // setIsSending(true);
      // const searchRes = await PromoClient.getVoucherActiveConfig({ offset: 0, search });
      // setPromoList(getData(searchRes));
      // setIsSending(false);
    },

    [isSending],
  );

  const handleSearchButton = (event) => {
    if (event.keyCode === 13) {
      searchPromo(text);
      event.preventDefault();
    }
  };
  // const nextFetch = () => {
  //   setOffset(offset + 20);
  //   // !totalList -> api k trả ra ngừng infinity scroll load
  //   if (promoList?.length === 0 || promoList?.length >= totalList || !totalList) {
  //     setIsHasMore(false);
  //   }
  // };
  useEffect(() => {
    if (visible) searchPromo(text);
  }, [visible]);

  const handleClickSearch = () => {
    searchPromo(text);
  };

  // tracking scroll in modal
  const [_, refScroll, onScroll] = useDetectScrollModal(visible);

  // TODO:

  return (
    <Modal className={styles.modalContainer} open={visible} {...restProps} onClose={onClose}>
      <>
        {loading && (
          <div className={styles.loadingContainer}>
            <LoadingBM />
          </div>
        )}
        <Grid className={styles.confirm_modal_wrap}>
          <Grid container className={styles.modal_title} item xs={12} justifyContent="space-between" alignItems="center">
            <Typography data-test="promo-text-modal">{translatePopupAction('popup-promotion.title')}</Typography>
            <CloseIcon className={styles.close} onClick={onClose} data-test="ic-close-modal" />
          </Grid>

          <Grid container className={styles.wrapAction} item xs={12} alignItems="center" justifyContent="space-between">
            <Grid item xs={12} sm={12}>
              <InputV2
                type={null}
                endAdornment={text === '' ? null : <CloseIcon onClick={() => handleChangeText('')} className={styles.clearSearch} />}
                placeholder={translatePopupAction('popup-promotion.enter_code_looking_for')}
                value={text}
                onChange={(e) => handleChangeText(e.target.value)}
                onKeyDown={handleSearchButton}
                style={{ height: 'auto' }}
                className={styles.inputV2Promo}
                // autoFocus
              />
            </Grid>

            {/* <Grid item xs={12} sm={3} container justifyContent="flex-end" className={styles.wrapBtnSearch}>
              <ButtonV2
                className={styles.button}
                onClick={handleClickSearch}
                tooltipTitle={translatePopupAction('popup-promotion.find_code')}
                hoverColor="#fff"
                hover
              >
                {translatePopupAction('popup-promotion.search')}
              </ButtonV2>
            </Grid> */}
          </Grid>
          <div className={styles.counpon_list_wapper}>
            <div
              className={clsx(styles.counpon_list, promoList?.length <= 0 && styles.notCode)}
              id="scrollableDiv"
              ref={refScroll}
              onScroll={onScroll}
            >
              {/* <InfiniteScroll
                inverse={false}
                next={promoList?.length < 20 ? null : nextFetch}
                dataLength={promoList?.length}
                hasMore={isHasMore}
                loader={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <div className={clsx(styles.loadMore, (promoList?.length < 20 || promoList?.length < 0) && styles.hiddenLoad)}>
                    <CircularProgress thickness={4} size={20} className={styles.LoadCircle} />
                    Đang tải...
                  </div>
                }
                scrollableTarget="scrollableDiv"
                endMessage={<div className={styles.loadMore} />}
              > */}
              {promoList?.length !== 0 ? (
                <Grid container spacing={1}>
                  {promoList?.map((voucher) => (
                    <Grid className={styles.coupon_card_grid} item key={voucher.code} style={{ width: '100%' }} data-test="modal-item-promo">
                      <CartCouponCardV2
                        {...voucher}
                        promoCode={voucher.code}
                        redeemCode={redeemCode}
                        handleChangePromo={handleChangePromo}
                        subPrice={subPrice}
                        accountId={accountId}
                        customerID={customerID}
                        currentPage={currentPage}
                        isMobile={isMobile}
                        redeemApplyResult={redeemApplyResult}
                        handleSectionPromoRefetch={(codes) => {
                          getVouchers(codes);
                        }}
                        // setTriggerRefetchPromo={setTriggerRefetchPromo}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <div className={styles.not_yet}>
                  <span data-test="modal-not-promo-text">{translatePopupAction('popup-promotion.no_code_yet')}</span>
                </div>
              )}
              {/* {console.log(promoList?.length < 0)} */}
              {/* </InfiniteScroll> */}
            </div>
          </div>
        </Grid>
      </>
    </Modal>
  );
});

export default PromoListModalV2;
