import { Badge, Box, Button, Container, Grid, Tooltip } from '@material-ui/core';
import { TrackingActionClient } from 'clients';
import clsx from 'clsx';
import MostSearch from 'components-v2/atoms/MostSearch';
import SearchInput from 'components-v2/mocules/SearchInput';
import ToggleProfile from 'components-v2/mocules/ToggleProfile';
import { LinkComp } from 'components/atoms';
import { CARTV2_ICON, MISSING_IMAGE } from 'constants/Images';
import { IC_NAVBAR_EDITOR, IC_NAVBAR_USER } from 'constants/Images/header';
import {
  CART_URL,
  KHUYEN_MAI,
  KHUYEN_MAI_LOADING,
  OLD_KHUYEN_MAI,
  PRODUCTS_LOADING_URL,
  PRODUCTS_URL,
  SELLERS,
  SELLERS_LOADING_URL,
} from 'constants/Paths';
import { getTrackingData } from 'constants/data';
import { useAuth, useCart, useSearch } from 'context';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';
import { ImageFallback, NotifyUtils, gtag } from 'utils';
import { ImageFallbackStatic, ImageFallbackStoreImage } from 'utils/ImageFallback';
import { getLinkProxy } from 'utils/ImageUtils';
import { v4 as uuidv4 } from 'uuid';
import { useStore } from 'zustand-lib/storeGlobal';
import styles from './styles.module.css';

const getUrl = (url, redirectUrl) => {
  if (redirectUrl) {
    return redirectUrl;
  }
  return url;
};

const mapDataTestLink = {
  'Sản Phẩm': 'nav-products',
  'Hoạt Chất': 'nav-ingredients',
  'Đặt Hàng Nhanh': 'nav-quick-order',
  'Khuyến Mãi': 'nav-promotion',
  'Mã Giảm Giá': 'nav-sale-voucher',
  'Nhà Bán Hàng': 'nav-sellers',
};

function Navbar({ isMobile }) {
  const { t: translateCommon } = useTranslation('common');
  const { isAuthenticated, toggleLogin, user, toggleSignUp } = useAuth();
  const { isFocus } = useSearch();
  const [scrollSticky, setScrollSticky] = useState(false);
  const router = useRouter();
  const { totalQuantity } = useCart();
  const hashtagTopSearch = useStore((state) => state.hashtagTopSearch);
  const menuBar = useStore((state) => state.menuBar);
  let menuBarRender = [];

  if (menuBar?.length > 0) {
    menuBarRender = menuBar.map((item, index) => {
      const { iconUrl, label, url } = item;
      return {
        id: index,
        name: label,
        icon: getLinkProxy(iconUrl),
        url,
        isNew: false,
        prefetch: false,
        width: '20px',
        height: '20px',
        eventPage: false,
        tracking: getTrackingData(url),
      };
    });
  }

  const getActivePage = () => {
    const { pathname } = router || {};
    if (pathname === '/products' || pathname === '/categories/[slug]' || pathname === '/manufacturers/[slug]' || pathname === PRODUCTS_LOADING_URL) {
      return PRODUCTS_URL;
    }
    if (pathname === SELLERS_LOADING_URL) {
      return SELLERS;
    }
    if (pathname === KHUYEN_MAI_LOADING || pathname === OLD_KHUYEN_MAI) {
      return KHUYEN_MAI;
    }
    return router.pathname;
  };

  const handleTracking = async (tracking) => {
    const { account: { accountId = '' } = {}, customerID = '' } = user || {};
    TrackingActionClient.tracking({
      page: tracking.trackingPath,
      accountId,
      customerID,
      eventAction: tracking.action,
      currentPage: window.location.href,
      isMobile,
    });
  };
  const handleCheck = (e, tracking) => {
    if (!isAuthenticated) {
      e.preventDefault();
      NotifyUtils.error(translateCommon('you_need_login'));
      setTimeout(() => {
        toggleLogin();
      }, 300);
    }
    handleTracking(tracking);
    // setClick(false);
  };

  useEffect(() => {
    const scrollChangeSticky = () => {
      // TODO: do có banner của insider nên + thêm 50
      if (window.scrollY >= 150) {
        setScrollSticky(true);
      } else {
        setScrollSticky(false);
      }
    };
    window.addEventListener('scroll', scrollChangeSticky);
    return () => window.removeEventListener('scroll', scrollChangeSticky);
  }, []);

  // MOCK DATA

  useEffect(() => {
    gtag.displayTopSearch();
  }, []);

  const checkShowAnimate = () => {
    if (!(isFocus && scrollSticky)) {
      return null;
    }
    if (isFocus && scrollSticky) {
      return styles.animateSearch;
    }
    return styles.notFocusAnimate;
  };

  // check first time sticky navbar
  const [flagStickySearch, setFlagStickySearch] = useState(false);
  useEffect(() => {
    if (isFocus && scrollSticky) {
      setFlagStickySearch(true);
    }
    if (!scrollSticky) {
      setFlagStickySearch(false);
    }
  }, [isFocus, scrollSticky]);

  const Cart = ({ className }) => (
    <Box>
      <LinkComp href={CART_URL} className={clsx(styles.cartLink, className)}>
        <Badge
          badgeContent={totalQuantity}
          max={1000}
          // invisible={totalQuantity === 0}
          color="secondary"
          overlap="rectangular"
          data-test="cart-num"
        >
          <Tooltip title={translateCommon('header.cart')} arrow>
            <Box className={styles.cart} data-test="cart-link">
              <ImageFallbackStatic src={CARTV2_ICON} width="20px" height="24px" layout="fixed" />
            </Box>
          </Tooltip>
        </Badge>
      </LinkComp>
    </Box>
  );

  const TopSearch = () => {
    if (!hashtagTopSearch || hashtagTopSearch?.length === 0) return <></>;

    return (
      <Box style={{ width: '100%', backgroundColor: '#ffffff' }}>
        <Container style={{ maxWidth: '1328px' }}>
          <Box className={styles.mostSearchWrap}>
            <Box className={styles.mostSearchTab}>
              {hashtagTopSearch?.map((tag) => (
                <MostSearch onClick={() => gtag.clickTopSearch(tag)} key={tag.code} link={tag.url} className={styles.mostSearch}>
                  {tag.hashtag}
                </MostSearch>
              ))}
            </Box>
          </Box>
        </Container>
      </Box>
    );
  };

  return (
    <>
      <Box className={clsx(styles.nav, scrollSticky && styles.sticky)} id="navbarSticky">
        <Container style={{ maxWidth: '1328px', padding: '0px' }} className={styles.wrap}>
          <Box className={clsx(styles.WrapmenuFocus, { [styles.WrapmenuFocusBottom]: !scrollSticky })}>
            <Grid
              container
              className={clsx(styles.menu)}
              alignContent="space-between"
              style={{ width: isFocus && scrollSticky ? '30%' : 'auto', transition: 'width 1s' }}
            >
              {scrollSticky ? (
                <>
                  {menuBarRender?.map((element) => (
                    <Grid
                      item
                      key={uuidv4()}
                      data-test={element.dataTest}
                      style={{ height: '100%' }}
                      className={clsx(styles.linkWrapper, element.url === getActivePage() && styles.linkWrapperActive)}
                    >
                      <LinkComp
                        className={clsx(styles.link, flagStickySearch && !isFocus && styles.labelShowAnimate)}
                        prefetch={element.prefetch || false}
                        name={isFocus ? '' : element.name}
                        href={getUrl(element.url, element.redirectUrl)} // TODO: check lại redirectUrl
                        color="#0E1983"
                        target={element.redirectUrl && '_blank'}
                        key={uuidv4()}
                        onClick={(e) => {
                          handleCheck(e, element.tracking);
                          gtag.clickMenubar(element);
                        }}
                        data-test={`${mapDataTestLink[element?.name] || null}`}
                      >
                        {/* {element.isNew && <span className={styles.badge}>Mới</span>} */}
                        {element?.icon && (
                          <Box className={styles.menuIcons}>
                            {element.icon.startsWith('/') ? (
                              <ImageFallbackStoreImage
                                width="15px"
                                height="15px"
                                src={element.icon}
                                fallbackSrc={MISSING_IMAGE}
                                alt={element.name}
                                layout="fixed"
                              />
                            ) : (
                              <ImageFallbackStoreImage
                                width="15px"
                                height="15px"
                                src={element.icon}
                                fallbackSrc={MISSING_IMAGE}
                                alt={element.name}
                                layout="fixed"
                              />
                            )}
                          </Box>
                        )}
                      </LinkComp>
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {menuBarRender?.map((element) => (
                    <Grid
                      item
                      key={uuidv4()}
                      data-test={element.dataTest}
                      style={{ height: '100%' }}
                      className={clsx(styles.linkWrapper, element.url === getActivePage() && styles.linkWrapperActive)}
                    >
                      <LinkComp
                        className={clsx(styles.link)}
                        prefetch={element.prefetch || false}
                        name={element.name}
                        href={getUrl(element.url, element.redirectUrl)}
                        color="#0E1983"
                        target={element.redirectUrl && '_blank'}
                        key={uuidv4()}
                        onClick={(e) => {
                          handleCheck(e, element.tracking);
                          gtag.clickMenubar(element);
                        }}
                        // data-test={`${mapDataTestLink[element?.name] || null}`}
                      >
                        {element?.icon && (
                          <Box className={styles.menuIcons}>
                            {element.icon.startsWith('/') ? (
                              <ImageFallbackStatic width="15px" height="15px" src={element.icon} alt={element.name} layout="fixed" />
                            ) : (
                              <ImageFallback width="15px" height="15px" src={element.icon} alt={element.name} layout="fixed" />
                            )}
                          </Box>
                        )}
                      </LinkComp>
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
            {/* off top search for prd */}
            {/* SEARCH PRODUCT */}
            {isAuthenticated && scrollSticky && (
              <Box
                className={clsx(styles.wrapSticky, checkShowAnimate())}
                style={{ width: scrollSticky && isFocus ? '75%' : '35%', transition: 'width 1s' }}
              >
                <SearchInput
                  className={clsx(styles.SearchInput, isFocus && scrollSticky && styles.scaleSearchInput)}
                  classCustom={styles.hiddenWidth}
                  scrollSticky={scrollSticky}
                />
                <Box className={styles.wrapStickRight}>
                  <Cart className={styles.cartSticky} />
                  <ToggleProfile
                    point={user?.point}
                    balance={user?.balance}
                    level={user?.level}
                    className={styles.profileSticky}
                    classCustom={styles.customProfile}
                    isInNavbar
                  />
                </Box>
              </Box>
            )}
            {!isAuthenticated && scrollSticky && (
              <Box className={styles.headerCenter_Status}>
                <Button
                  data-test="btn-signup"
                  className={styles.buttonStyle}
                  onClick={toggleSignUp}
                  startIcon={<ImageFallbackStatic src={IC_NAVBAR_EDITOR} layout="fixed" width="20px" height="20px" />}
                  hoverColor="#0cba69"
                  disableRipple
                >
                  {translateCommon('header.signup')}
                </Button>
                <Button
                  data-test="btn-signin"
                  className={styles.buttonStyle}
                  onClick={toggleLogin}
                  startIcon={<ImageFallbackStatic src={IC_NAVBAR_USER} layout="fixed" width="20px" height="20px" />}
                  hoverColor="#0cba69"
                  disableRipple
                >
                  {translateCommon('header.login')}
                </Button>
              </Box>
            )}
          </Box>
        </Container>
        {/* <TopSearch /> */}
      </Box>
    </>
  );
}
export default memo(Navbar);
